import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import PageMenu from '../../../components/PageMenu/PageMenu';
import mortgageResourcesMenu from '../../../data/page-menus/mortgage-resources';
import { IconTypes } from '../../../components/Icon/Icon';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';

import './style.scss';

type Props = PageProps & WithTranslation;

const ResidentialMortgages: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('residentialMortgages.seo.title'),
    },
    page: {
      title: t('residentialMortgages.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.FOREST_GREEN,
        heroImage: {
          fileName: 'hero-image-12.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const links: any = {
    renew: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.RENEW_YOUR_MORTGAGE),
    refinance: getPathFromRouteKey(
      RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.REFINANCING_MORTGAGE
    ),
    homePurchase: getPathFromRouteKey(
      RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.PURCHASING_HOME
    ),
  };

  const generateInfoBlocks = (listItems: any[]) =>
    listItems.map((listItem) => (
      <div className="ResidentialMortgages__generic-gap" key={listItem.linkTag}>
        <h5>{listItem.description}</h5>
        <ButtonLink
          iconOptions={{
            icon: IconTypes.ARROW_NEXT,
          }}
          styleOptions={{ isInline: true }}
          to={links[listItem.linkTag] as string}
        >
          {listItem.buttonLabel}
        </ButtonLink>
      </div>
    ));

  return (
    <Layout options={layoutOptions} className="ResidentialMortgages">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="ResidentialMortgages__generic-gap">
              <p>
                <Trans t={t} i18nKey="residentialMortgages.body" />
              </p>
            </div>
            {generateInfoBlocks(t('residentialMortgages.selections', { returnObjects: true }))}
            <div className="ResidentialMortgages__generic-gap">
              <p>
                <Trans
                  i18nKey="understandingYourMortgage.needMoreInformation.description"
                  t={t}
                  values={{
                    tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
                  }}
                >
                  <ExternalLink href={CommonData.websites.fcacMortgages[activeLocaleTag]} />
                  <ExternalLink href={CommonData.websites.cbaMortgages[activeLocaleTag]} />
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                    }`}
                  />
                </Trans>
              </p>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              className="HomeWarranty__generic-gap"
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(ResidentialMortgages);
